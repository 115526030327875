import { sendGet, sendPost } from './index'
import { QueryData, ListRes } from './type'
// 更新用户 共享
export interface CommunalUpdateUserData {
    nickname: string,
    roles: string[],
    desc: string
}
// 获取用户列表 管理
export interface AdminGetUserRes {
    id: number,
    username: string,
    nickname: string,
    roleNames: string[],
    roles: string[],
    companyId: number,
    company: {
        companyName: string,
        contact: string,
        customerCode: string
    },
    disabled: boolean,
    desc: string,
    createdAt: string,
}
// 获取用户列表
export interface GetUserRes {
    id: number,
    username: string,
    nickname: string,
    roleNames: string[],
    roles: string[],
    companyId: number,
    disabled: boolean,
    desc: string,
    createdAt: string,
}

export const userModule = {
    // 获取用户列表 管理
    adminGetUser: (data: QueryData) => sendPost<ListRes<AdminGetUserRes>>('/admin/user/list', data),
    // 禁用用户 管理
    adminDisableUser: (id: any) => sendGet(`/admin/user/disable/${id}`),
    // 启用用户 管理
    adminEnableleUser: (id: any) => sendGet(`/admin/user/enable/${id}`),
    //添加用户 管理
    adminAddUser: (data: any) => sendPost('/admin/user/create', data),
    //更新用户 管理
    adminUpdateUser: (id: number, data: CommunalUpdateUserData) => sendPost(`/admin/user/update/${id}`, data),
    // 重置密码 管理
    adminResetPassword: (data: any) => sendPost('/admin/user/rest-password', data),
    // 获取用户ApiKey 管理
    adminGetApiKey: (name: string) => sendGet(`/auth/x-api-key/${name}`),
    // 获取用户列表
    getUser: (data: QueryData) => sendPost<GetUserRes>('/user/list', data),
    // 禁用用户
    disableUser: (id: any) => sendGet(`/user/disable/${id}`),
    // 启用用户
    enableleUser: (id: any) => sendGet(`/user/enable/${id}`),
    //添加用户
    addUser: (data: any) => sendPost('/user/create', data),
    // 更新用户
    updateUser: (id: number, data: CommunalUpdateUserData) => sendPost(`/user/update/${id}`, data),
    // 重置密码
    resetPassword: (data: any) => sendPost('/user/rest-password', data),
}