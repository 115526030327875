import { sendGet, sendPost } from './index'
import type { QueryData, ListRes } from './type'

// 类型
export enum BillType {
    /** 充值 */
    Recharge = '充值',
    /** 退款 */
    Refund = '退款',
    /** 添加信用额度 */
    Credit = '添加信用额度',
    /** 撤销信用额度 */
    CreditDeduction = '撤销信用额度',
    /** 冻结 */
    Freeze = '冻结',
    /** 打单冻结 */
    LabelFreeze = '打单冻结',
    /** 解冻 */
    Unfreeze = '解冻',
    /** 打单失败解冻 */
    LabelFailedUnfreeze = '失败解冻',
    LabelCancelUnfreeze= '取消解冻',
    /** 结算解冻 */
    SettlementUnfreeze = '结算解冻',
    /** 结算扣款 */
    SettlementDeduction = '结算扣款',
    /** 第三方扣款 */
    ThirdPartyDeduction = '第三方扣款',
    /** 结算退款 */
    SettlementRefund = '结算退款',
    /** 第三方退款 */
    ThirdPartyRefund = '第三方退款',
}
// 获取账户列表 管理
export interface GetAccountListRes {
    availableBalance: string,
    company: {
        companyName: string,
        customerCode: string,
    },
    companyId: string,
    createdAt: string,
    creditAmount: string,
    freezeAmount: string,
    totalCost: string,
    totalIncome: string,
    totalRecharge: string
}
// 结算状态
export enum SettlementStatus {
    /** 待结算 */
    Pending = 'Pending',
    /** 部分结算 */
    Partial = 'Partial',
    /** 已结算 */
    Settled = 'Settled',
}
// 获取收费明细 管理
export interface AdminGetDetailedListRes {
    createdAt: string,
    orderNumber: string,
    referenceNo: string,
    trackingNumber: string,
    desc: string,
    amount: string,
    productCode: string,
    settlementAmount: string,
    region: string,
    zipFirstNumber: string,
    settlementStatus: SettlementStatus
}
// 导出收费明细 管理
export interface AdminExportChargDetailsData {
    batchId: string
}
//获取账单列表 管理
export interface AdminGetCheckListRes {
    id: number,
    amount: string,
    billDate: string,
    chargeType: string,
    createdAt: string,
    desc: string,
    orderNumber: string,
    productCode: string,
    referenceNo: string,
    status: SettlementStatus,
    trackingNumber: string
}
// 上传账单 管理
export interface AdminUploadBillRes {
    batchId: string
}
// 新建账单 管理
export interface AdminNewBillData {
    orderNumber: string,
    billDate: string,
    trackingNumber: string,
    amount: number,
    settlement: boolean,
    chargeType: string,
    desc?: string
}
// 获取账单列表
export interface GetCheckListRes {
    amount: string,
    billDate: string,
    chargeType: string,
    createdAt: string,
    desc: string,
    orderNumber: string,
    productCode: string,
    referenceNo: string,
    trackingNumber: string
}

export const financeModule = {
    //获取账户列表
    getAccountList: (data: QueryData) => sendPost<ListRes<GetAccountListRes>>('/admin/account/list', data),
    // 充值
    recharge: (data: any) => sendPost('/admin/account/operation', data),
    // 获取调整历史记录
    getAdjustmentsList: (data: any) => sendPost('/admin/bill/list', data),
    // 获取收费明细 管理
    adminGetDetailedList: (data: QueryData) => sendPost<ListRes<AdminGetDetailedListRes>>('/admin/order/charges', data),
    //导出收费明细 管理
    adminExportChargDetails: (data: QueryData) => sendPost<AdminExportChargDetailsData>('/processor/excel/charges/admin', data),
    // 获取账户列表 管理
    adminGetBillList: (data: any) => sendPost('/admin/bill/list', data),
    //获取账单列表 管理
    adminGetCheckList: (data: QueryData) => sendPost<ListRes<AdminGetCheckListRes>>('/admin/order/settlements', data),
    // 立即结算 管理
    adminImmediateSettlement: (id: number) => sendGet(`/admin/order/settlement/${id}`),
    // 导入账单 管理
    adminUploadBill: (data: FormData) => sendPost<AdminUploadBillRes>('/processor/excel/settlements/upload', data),
    //新建账单 管理
    adminNewBill: (data: AdminNewBillData) => sendPost('/admin/order/settlement/create', data),
    // 导出流水 管理
    adminExportFlowchart: (data: QueryData) => sendPost('/processor/excel/bills/admin', data),
    // 获取账户列表
    getBillList: (data: any) => sendPost('/bill/list', data),
    //获取账单列表
    getCheckList: (data: QueryData) => sendPost<ListRes<GetCheckListRes>>('/order/settlements', data),
    // 导出流水
    exportFlowchart: (data: QueryData) => sendPost('/processor/excel/bills', data),
}
