import { sendGet, sendPost } from './index'
import type { ListRes } from './type'

// 获取导入订单
interface GetImportedOrdersList<T> extends ListRes<T> {
    tabCounts: {
        status: string,
        count: number
    }[]
}
export interface GetImportedOrdersRes {
    address1: string
    address2: string
    batchId: string
    city: string
    companyId: number
    companyName: string
    country: string
    createdAt: string
    email: string
    errorMessage: string
    height: string
    id: number
    length: string
    lengthUnit: string
    massUnit: string
    name: string
    phone: string
    productNumber: string
    quantity: number
    referenceNo: string
    state: string
    status: string
    updatedAt: string
    weight: string
    width: string
    zip: string
    rowNumber: number
}
// 查询创单结果
interface CreateOrderResultRes {
    excelPath: {
        succeededCount: number
        failedCount: number
    }
    failedReason: string
    isCompleted: boolean
    isFailed: boolean
}
// 查询最近导入记录
export interface ImportRecordsRes {
    batchId: string
    createdAt: string
    count:number
    failedCount:number
    succeededCount:number
    fileName:string
}

export const templateOrderModule = {
    //导入订单
    importOrder: (data: any) => sendPost<{ batchId: string }>('/processor/excel/batch-order/upload', data),
    // 获取导入的订单
    getImportedOrders: (data: any) => sendPost<GetImportedOrdersList<GetImportedOrdersRes>>('/order/batch/list', data),
    // 编辑导入的订单
    editImportedOrders: (id: number, data: any) => sendPost(`/order/batch/${id}`, data),
    // 创建订单
    importedOrdersCreateOrder: (data: any) => sendPost<{ batchId: string }>('/processor/batch-create', data),
    // 查询创单结果
    createOrderResult: (id: string) => sendGet<CreateOrderResultRes>(`/processor/batch-create/${id}`),
    // 查询最近导入记录
    importRecords: () => sendGet<ImportRecordsRes[]>('/order/batch/history')
}