import { sendGet, sendPost } from './index'
import type { QueryData, ListRes } from './type'

// 获取报价列表
export interface GetQuotationListData {
    id: number,
    amount: string,
    chargeType: string,
    createdAt: string,
    disabled: boolean,
    planName: string,
    updatedAt: string
}
// 添加单价计划
export interface AddUnitPricePlanRes {
    amount: number
}
// 获取产品列表
export interface GetProductRes {
    carrier: string,
    desc: string,
    productCode: string,
    productName: string,
    senderOptions: string
    supportMultiSpec: boolean
    supportSignature: boolean
    supportInsurance: boolean
    senders: any[]
    maxPkgCount: number
    minPkgCount:number
    maxSpecCount: number
}
// 获取产品列表 管理 可查询
export interface AdminGetProductListRes {
    id: number
    carrier: string,
    desc: string,
    productCode: string,
    productName: string,
    senderOptions: string
    quoteOptions: string
    cancelOptions: string
    labelOptions: string
    verificationOptions: string
    auxiliaryOptions: string
    disabled: boolean
    lengthUnit: string
    massUnit: string
    maxPkgCount: number
    minPkgCount: number
    maxWeight: string
    sort: string
    supportCancel: boolean
    supportMultiSpec: boolean
    supportSignature: boolean
    supportInsurance: boolean
    config: {
        id: number
        name: string
    }
    provider: {
        id: number
        providerName: string
        providerCode: string
    }
    maxSpecCount: number
}
// 添加产品 编辑产品
export interface AddEditProductData {
    carrier: string,
    providerId: number,
    configId: number,
    productName: string,
    productCode: string,
    desc: string,
    sort: number,
    disabled: boolean,
    senderOptions: string,
    maxWeight: string,
    massUnit: string,
    lengthUnit: string,
    maxPkgCount: number,
    quoteOptions: string
    labelOptions: string
    cancelOptions: string
    supportCancel: boolean
}
// 获取渠道列表
export interface GetChannelListRes {
    createdAt: string
    desc: string
    disabled: boolean
    id: number
    providerCode: string
    providerName: string
    config: ConfigType[]
}
export interface ConfigType {
    createdAt: string
    id: number
    name: string
    options: string
    providerId: number
    updatedAt: string
}
// 添加编辑渠道
interface AddEditChannel {
    providerName: string,
    providerCode: string,
    desc: string,
    disabled: boolean
}
// 添加渠道配置
export interface AddChannelConfigData {
    name: string
    providerId: number
    options: string
}

// 获取报价方案
export interface GetQuotationSchemeRes {
    createdAt: string
    id: number
    solutionCode: string
    solutionDesc: string
    solutionName: string
    solutionTemplate: string
    supportAdd: boolean
    updatedAt: string
}
// 获取计划列表
export interface GetPlanListRes {
    createdAt: string
    id: number
    planCode: string
    planName: string
    planOptions: string
    refValue: string
    solutionId: string
    updatedAt: string
}

export const systemModule = {
    // 获取报价列表
    getQuotationList: (data: QueryData) => sendPost<ListRes<GetQuotationListData>>('/admin/solution/unit-price/list', data),
    // 添加单价计划
    addUnitPricePlan: (data: AddUnitPricePlanRes) => sendPost('/admin/solution/unit-price/create', data),
    //获取产品列表 管理
    adminGetProduct: () => sendGet<GetProductRes[]>('/admin/product/list'),
    // 获取产品列表
    GetProduct: () => sendGet<GetProductRes[]>('/product/list'),
    // 获取产品列表 管理 可查询
    adminGetProductList: (data: QueryData) => sendPost<ListRes<AdminGetProductListRes>>('/admin/product/list', data),
    // 添加产品
    addProduct: (data: AddEditProductData) => sendPost('/admin/product/create', data),
    // 编辑产品
    editProduct: (id: number, data: AddEditProductData) => sendPost(`/admin/product/update/${id}`, data),
    // 获取渠道列表
    getChannelList: (data: QueryData) => sendPost<ListRes<GetChannelListRes>>('/admin/provider/list', data),
    // 添加渠道
    addChannel: (data: AddEditChannel) => sendPost('/admin/provider/create', data),
    // 修改渠道
    editChannel: (id: number, data: AddEditChannel) => sendPost(`/admin/provider/update/${id}`, data),
    // 添加渠道配置
    addChannelConfig: (data: AddChannelConfigData) => sendPost('/admin/provider/config/create', data),
    // 修改渠道配置
    editChannelConfig: (id: number, data: { name: string, options: string }) => sendPost(`/admin/provider/config/update/${id}`, data),

    // 获取报价方案
    getQuotationScheme: (data: any) => sendPost<ListRes<GetQuotationSchemeRes>>('/admin/solution/list', data),
    // 获取计划列表
    getPlanList: (data: any) => sendPost<ListRes<GetPlanListRes>>('/admin/plan/list', data),
    // 添加计划
    addPlan: (data: any) => sendPost('/admin/plan/create', data)
}
