import axios from 'axios'
import store from '@/redux/store'
import { loginExpiresChenge } from '@/redux/actions'
import { message, notification } from 'antd'
import { shareModule } from './share'
import { loginModule } from './Login'
import { createOrderModule } from './placeOrder'
import { orderModule } from './orderManage'
import { financeModule } from './financeManage'
import { addressModule } from './addressManage'
import { userModule } from './userManage'
import { companyModule } from './companyManage'
import { systemModule } from './systemManage'
import { templateOrderModule } from './templateOrder'
import type { AxiosRequestConfig } from 'axios'
import type { ApiResponse, ApiRecastResponse } from './type'

// let reqList: string[] = []
// const stopRepeatRequest = function (reqList: string[], url: string, cancel: Canceler) {
//     for (let i = 0; i < reqList.length; i++) {
//         if (reqList[i] === url) {
//             cancel("频繁操作")
//             return
//         }
//     }
//     reqList.push(url)
// }
// const allowRequest = function (reqList: string[], url: string) {
//     for (let i = 0; i < reqList.length; i++) {
//         if (reqList[i] === url) {
//             reqList.splice(i, 1)
//             break
//         }
//     }
// }

const serivce = axios.create({
    baseURL: window.configInfo.baseIP
    // baseURL: "/test"
})

serivce.interceptors.request.use(
    (config) => {
        // config.withCredentials = true;  //允许携带cookie信息
        let tem = localStorage.getItem("IntimidateAccountInformation")
        if (tem) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
            let info = JSON.parse(tem)
            config.headers!.STag = window.configInfo.sTag
            if (info.accessToken) {
                config.headers!.Authorization = `Bearer ${info.accessToken}`
            }
            if (info["x-api-key"]) {
                config.headers!["x-api-key"] = `${info["x-api-key"]}`
            }
        }
        // let cancel: Canceler = (message?: string) => { }
        // config.cancelToken = new axios.CancelToken((e) => {
        //     cancel = e
        // })
        // stopRepeatRequest(reqList, config.url!, cancel)
        return config;
    },
    err => {
        return Promise.reject(err);
    });

serivce.interceptors.response.use(
    response => {
        // setTimeout(() => {
        //     allowRequest(reqList, response.config.url!)
        // }, 1000)
        return response
    }, error => {
        if (axios.isCancel(error)) {
            notification.error({
                message: "错误提示",
                description: error.message
            })
        } else {
            // setTimeout(() => {
            //     allowRequest(reqList, error.config.url)
            // }, 1000)
            if (error.response.status === 500 || error.response.status === 502) {
                message.error(error.response.statusText)
            } else if (error.response.status === 401) {
                store.dispatch(loginExpiresChenge(true))
            } else {
                let err = ""
                if (error.response.status === 0) {
                    err = error.message
                } else {
                    err = error.response.data.language ? error.response.data.language["zh-cn"] : error.response.data.message
                }
                message.open({
                    type: 'error',
                    content: err,
                    duration: 6,
                })
            }
        }
        return Promise.reject(error)
    }
)

export const sendGet = <R>(url: string, config?: AxiosRequestConfig) => {
    return new Promise<ApiRecastResponse<R>>((resolve, reject) => {
        serivce.get<ApiResponse<R>>(url, config).then(res => {
            resolve({
                ...res.data,
                config: res.config
            })
        }, err => {
            reject(err)
        })
    })
}

export const sendPost = <R, D = any>(url: string, data: D, config?: AxiosRequestConfig) => {
    return new Promise<ApiRecastResponse<R>>((resolve, reject) => {
        serivce.post<ApiResponse<R>>(url, data, config).then(res => {
            resolve({
                ...res.data,
                config: res.config
            })
        }, err => {
            reject(err)
        })
    })
}

export const sendDel = <R>(url: string, config?: AxiosRequestConfig) => {
    return new Promise<ApiRecastResponse<R>>((resolve, reject) => {
        serivce.delete<ApiResponse<R>>(url, config).then(res => {
            resolve({
                ...res.data,
                config: res.config
            })
        }, err => {
            reject(err)
        })
    })
}

// 汇总api
const requestList = {
    // 共享API模块
    ...shareModule,
    //登录模块
    ...loginModule,
    //创建面单模块
    ...createOrderModule,
    //批量创建订单
    ...templateOrderModule,
    //订单管理模块
    ...orderModule,
    //用户管理模块
    ...userModule,
    //公司管理模块
    ...companyModule,
    //财务管理模块
    ...financeModule,
    //地址管理模块
    ...addressModule,
    // 系统管理
    ...systemModule
}

export default requestList 