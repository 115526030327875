import loadable from './Loadable'
import { useRoutes } from 'react-router-dom';
import routes from './routerList'
import AuthRouter from './AuthRouter'
import type { RouteType } from './type'

let finalRoutes: RouteType[] = [];

// 创建路由
(function () {
    const renderRoutes = (routes: RouteType[]): RouteType[] => {
        return routes.map((item) => {
            if (item.children) {
                let Page = loadable(() => import(`../views/${item.element}`))
                return {
                    ...item,
                    element: <AuthRouter stricture={item.meta?.stricture}><Page /></AuthRouter>,
                    children: renderRoutes(item.children)
                }
            } else {
                if (item.path === '') {
                    return item
                }
                let Page = loadable(() => import(`../views/${item.element}`))
                return {
                    ...item,
                    element: <AuthRouter stricture={item.meta?.stricture}><Page /></AuthRouter>
                }
            }
        })
    }
    finalRoutes = renderRoutes(routes)
}())

// 注册路由
const RegisterRoute = () => {
    let elements = useRoutes(finalRoutes)
    return elements
}

//根据路径获取路由对象
const checkRouterAuth = (path: string): RouteType | null => {
    let auth: RouteType | null = null
    auth = checkAuth(routes, path)
    return auth
}
const checkAuth = (routers: RouteType[], path: string): RouteType | null => {
    for (const item of routers) {
        let tem = item.path.split(':')[0]
        if ((path.includes(tem) && item.meta)) return item
        if (item.children) {
            const data = checkAuth(item.children, path)
            if (data) return data
        }
    }
    return null
}

export { checkRouterAuth, RegisterRoute }

