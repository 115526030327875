// 路由鉴权
import { Navigate } from 'react-router-dom';

interface PorpsType {
    children: JSX.Element,
    stricture: string[] | undefined
}

const AuthRouter: React.FC<PorpsType> = (porps) => {
    let getItem = localStorage.getItem("IntimidateAccountInformation")

    if (porps.stricture && getItem) {
        let roles = JSON.parse(getItem).roles
        if (roles.some((val: string) => porps.stricture!.includes(val))) {
            return porps.children
        } else {
            return <Navigate to='/Home' />
        }
    } else {
        return porps.children
    }
}

export default AuthRouter