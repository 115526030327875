import { combineReducers } from 'redux'
import { LOGINEXPIRESCHANGE, WHOLELOADINGCHANGE, MAKEANOTHERORDERDATACHANGE } from './const'
import type { MakeAnotherOrderDataType } from './type'

// 登录是否过期
const initLoginExpires: boolean = false
const loginExpires = (prevState = initLoginExpires, action: { type: string, data: boolean }) => {
    const { type, data } = action
    switch (type) {
        case LOGINEXPIRESCHANGE:
            return data
        default:
            return prevState
    }
}

// 全局加载效果
const initWholeLoading: boolean = false
const WholeLoading = (prevState = initWholeLoading, action: { type: string, data: boolean }) => {
    const { type, data } = action

    switch (type) {
        case WHOLELOADINGCHANGE:
            if (data) {
                document.body.classList.add("globalClassOne")
            } else {
                document.body.classList.remove("globalClassOne")
            }
            return data
        default:
            return prevState
    }
}

// 再打一单数据暂存
const initMakeAnotherOrderData: MakeAnotherOrderDataType | null = null
const makeAnotherOrderData = (prevState = initMakeAnotherOrderData, action: { type: string, data: MakeAnotherOrderDataType }) => {
    const { type, data } = action
    switch (type) {
        case MAKEANOTHERORDERDATACHANGE:
            return data
        default:
            return prevState
    }
}

// 汇总reducers
export default combineReducers({
    loginExpires,
    WholeLoading,
    makeAnotherOrderData
}) 