//共享API
import { sendGet } from './index'

// 查询导出结果 Excel Pdf
export interface GetExportResultRes {
    excelPath: string
    failedReason: string
    isCompleted: boolean
    isFailed: boolean
}

export const shareModule = {
    // 查询Excel导出结果
    getExportExcelResult: (id: string) => sendGet<GetExportResultRes>(`/processor/excel/${id}`),
    // 查询Pdf导出结果
    getExportPdfResult: (id: string) => sendGet<GetExportResultRes>(`/processor/pdf/${id}`),
}
