import { sendGet, sendPost } from './index'
import type { QueryData, ListRes } from './type'
import type { SpecsType } from './placeOrder'
import type { AddressType } from './addressManage'

// 订单状态
export enum OrderStatus {
    Pending = '等待打单',
    Processing = '正在打单',
    Succeeded = '打单成功',
    Failed = '打单失败',
    Cancelled = '打单取消',
    Closed = '打单关闭',
}
// 打单状态Tag颜色
export enum OrderStatusColor {
    Pending = 'blue',
    Processing = 'magenta',
    Succeeded = 'green',
    Failed = 'error',
    Cancelled = 'gold',
    Closed = 'default',
}
//订单列表 基础字段
interface OrderListInfo {
    productCode: string,
    orderNumber: string,
    product: {
        carrier: string,
        productName: string,
        supportCancel: boolean
    },
    estimatedTime: string,
    createdAt: string,
    status: string,
    referenceNo: string,
    totalPkgCount: number,
    trackingNumbers: TrackingNumbersType[],
    labelPdfUrl: string,
    sender: AddressType,
    receiver: AddressType,
    errorMessage: any,
    signatureOption: string
    user: {
        nickname: string,
        username: string
    },
    orderAmount: {
        estimatedAmount: string,
        freezeAmount: string,
        settlementAmount: string
    },
    settlementStatus: string
    masterTrackingNumber: string
}
// 订单列表 trackingNumbers
export interface TrackingNumbersType {
    labelPdfUrl: string,
    trackingNumber: string
}
// 获取订单列表 管理
export interface AdminGetOrderListRes extends OrderListInfo {
    company: {
        companyName: string,
        recreate: boolean
    },
}
// 获取订单列表
export interface GetOrderListRes extends OrderListInfo {
    company: {
        companyName: string
    },
}
// 获取订单详情 管理
export interface AdminGetOrderDetailsRes extends OrderListInfo {
    company: {
        companyName: string,
        recreate: boolean
    },
    specs: SpecsType[],
}
// 获取订单详情
export interface GetOrderDetailsRes extends OrderListInfo {
    company: {
        companyName: string,
    },
    specs: SpecsType[],
}
// 冻结类型
export enum BillType {
    Freeze = 'Freeze',
    Unfreeze = 'Unfreeze'
}
// 冻结解冻
export interface freezeThawData {
    orderNumber: string,
    billType: BillType,
    amount: number,
    desc: string
}
// 获取当前打单数量
export interface getOrderQuantityRes {
    count: number
}
// 批量解冻
export interface BatchThawData {
    orderNumber: string
    amount: number
    desc?: string
}

export const orderModule = {
    //获取订单列表 管理
    adminGetOrderList: (data: QueryData) => sendPost<ListRes<AdminGetOrderListRes>>('/admin/order/list', data),
    // 获取订单详情 管理
    adminGetOrderDetails: (id: string) => sendGet<AdminGetOrderDetailsRes>(`/admin/order/details/${id}`),
    // 导出订单 管理
    adminExportOrder: (data: any) => sendPost('/processor/excel/orders/admin', data),
    // 面单合并下载 管理
    adminMergeDownload: (data: QueryData) => sendPost<{ batchId: string }>('/processor/pdf/merge/admin', data),
    // 冻结解冻 管理
    freezeThaw: (data: freezeThawData) => sendPost('/admin/order/freeze-unfreeze', data),
    // 批量解冻 管理
    batchThaw: (data: BatchThawData[]) => sendPost('/admin/order/batch-unfreeze', data),
    //获取订单列表
    GetOrderList: (data: QueryData) => sendPost<ListRes<GetOrderListRes>>('/order/list', data),
    // 获取订单详情
    getOrderDetails: (id: string) => sendGet<GetOrderDetailsRes>(`/order/details/${id}`),
    // 导出订单
    exportOrder: (data: any) => sendPost('/processor/excel/orders', data),
    //订单列表打单
    OrderIntimidate: (data: any) => sendPost('/order/label', data),
    // 关闭打单
    closePrint: (data: any) => sendPost('/order/close', data),
    // 打单取消
    cancellationPrinting: (id: string) => sendGet(`/order/cancel/${id}`),
    // 获取当前打单数量
    getOrderQuantity: () => sendGet<getOrderQuantityRes>('/dadan/pending-count'),
    // 面单合并下载
    mergeDownload: (data: QueryData) => sendPost<{ batchId: string }>('/processor/pdf/merge', data),
}
