import { Navigate } from 'react-router-dom';
import type { RouteType } from './type'

const routes: RouteType[] = [
    {
        path: '/',
        element: 'LogIn/LogIn',
    },
    {
        path: '/Home',
        element: 'Home/Home',
        children: [
            {
                path: '',
                element: <Navigate to='HomePage' />
            },
            {
                path: "HomePage",
                element: 'HomePage/HomePage',
                meta: {
                    title: "首页",
                    stricture: ['SystemAdmin', 'SystemOperator', 'SystemFinance', 'CompanyAdmin', 'CompanyOperator', 'CompanyFinance']
                }
            },
            {
                path: "PlaceOrder",
                element: 'PlaceOrder/PlaceOrder',
                meta: {
                    title: "创建订单",
                    stricture: ['SystemAdmin', 'SystemOperator', 'CompanyAdmin', 'CompanyOperator'],
                }
            },
            {
                path: "TemplateOrder",
                element: 'TemplateOrder/TemplateOrder',
                meta: {
                    title: "创建订单-批量",
                    stricture: ['SystemAdmin', 'SystemOperator', 'CompanyAdmin', 'CompanyOperator'],
                }
            },
            // 订单管理
            {
                path: "AdminOrderList",
                element: 'OrderManage/AdminOrderList/AdminOrderList',
                meta: {
                    title: "订单列表-管理",
                    stricture: ['SystemAdmin', 'SystemOperator', 'SystemFinance']
                }
            },
            {
                path: "OrderList",
                element: 'OrderManage/OrderList/OrderList',
                meta: {
                    title: "订单列表",
                    stricture: ['SystemAdmin', 'SystemOperator', 'SystemFinance', 'CompanyAdmin', 'CompanyOperator', 'CompanyFinance']
                }
            },
            {
                path: "AdminOrderDetails/:id",
                element: 'OrderManage/AdminOrderDetails/AdminOrderDetails',
                meta: {
                    title: "订单详情-管理",
                    stricture: ['SystemAdmin', 'SystemOperator', 'SystemFinance']
                }
            },
            {
                path: "OrderDetails/:id",
                element: 'OrderManage/OrderDetails/OrderDetails',
                meta: {
                    title: "订单详情",
                    stricture: ['SystemAdmin', 'SystemOperator', 'SystemFinance', 'CompanyAdmin', 'CompanyOperator', 'CompanyFinance']
                }
            },
            // 财务管理
            {
                path: "AdminAccountList",
                element: 'FinanceManage/AdminAccountList/AdminAccountList',
                meta: {
                    title: "账户列表-管理",
                    stricture: ['SystemAdmin', 'SystemFinance']
                }
            },
            {
                path: "AdminChargeDetails",
                element: 'FinanceManage/AdminChargeDetails/AdminChargeDetails',
                meta: {
                    title: "收费明细-管理",
                    stricture: ['SystemAdmin', 'SystemFinance']
                }
            },
            {
                path: "AdminBillList",
                element: 'FinanceManage/AdminBillList/AdminBillList',
                meta: {
                    title: "账户流水-管理",
                    stricture: ['SystemAdmin', 'SystemFinance']
                }
            },
            {
                path: "BillList",
                element: 'FinanceManage/BillList/BillList',
                meta: {
                    title: "账户流水",
                    stricture: ['CompanyAdmin', 'CompanyFinance']
                }
            },
            {
                path: 'AdminCheckList',
                element: 'FinanceManage/AdminCheckList/AdminCheckList',
                meta: {
                    title: "账单列表-管理",
                    stricture: ['SystemAdmin', 'SystemFinance']
                }
            },
            {
                path: 'CheckList',
                element: 'FinanceManage/CheckList/CheckList',
                meta: {
                    title: "账单列表",
                    stricture: ['CompanyAdmin', 'CompanyFinance']
                }
            },
            // 用户管理
            {
                path: "AdminUserList",
                element: 'UserManage/AdminUserList/AdminUserList',
                meta: {
                    title: "用户列表-管理",
                    stricture: ['SystemAdmin']
                }
            },
            {
                path: "UserList",
                element: 'UserManage/UserList/UserList',
                meta: {
                    title: "用户列表",
                    stricture: ['SystemAdmin', 'CompanyAdmin']
                }
            },
            // 公司管理
            {
                path: "AdminCompanyList",
                element: 'CompanyManage/AdminCompanyList/AdminCompanyList',
                meta: {
                    title: "公司列表-管理",
                    stricture: ['SystemAdmin']
                }
            },
            // 地址管理
            {
                path: "AddressList",
                element: 'AddressManage/AddressList/AddressList',
                meta: {
                    title: "地址列表",
                    stricture: ['SystemAdmin', 'SystemOperator', 'CompanyAdmin', 'CompanyOperator']
                }
            },
            //系统管理
            {
                path: "ProductList",
                element: 'SystemManage/ProductList/ProductList',
                meta: {
                    title: "产品管理",
                    stricture: ['SystemAdmin']
                }
            },
            {
                path: "ChannelConfig",
                element: 'SystemManage/ChannelConfig/ChannelConfig',
                meta: {
                    title: "渠道管理",
                    stricture: ['SystemAdmin']
                }
            },
            {
                path: "ProgrammeManage",
                element: 'SystemManage/ProgrammeManage/ProgrammeManage',
                meta: {
                    title: "报价方案",
                    stricture: ['SystemAdmin']
                }
            }
        ]
    },
]

export default routes