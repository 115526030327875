import { sendGet, sendPost } from './index'

// 获取FBA地址列表
export interface GetFbaAddressRes {
    id: number,
    address1: string,
    address2: string,
    city: string,
    companyName: string,
    fullAddress: string,
    country: string,
    name: string,
    state: string,
    zip: string
}
// 邮编发生变化实时获取地址
export interface ChengeZipData {
    carrier: string,
    country: string,
    zip: string
}
export interface ChengeZipRes {
    city: string,
    state: string,
    zip: string
}
// 包裹规格类型
export type SpecsType = {
    massUnit: string,
    lengthUnit: string,
    length: number,
    width: number,
    height: number,
    weight: number,
    quantity: number,
    productNumber: string,
    insuredValue: number
    boxNos: string[],
}
// 地址类型
export type AddressType = {
    saveAddress?: boolean,
    address1: string,
    address2: string,
    city: string,
    companyName: string,
    country: string,
    email: string,
    name: string,
    phone: string,
    state: string,
    zip: string,
}
// 报价
export interface OfferData {
    productCode: string,
    senderId?: string | number
    sender: AddressType,
    receiver: AddressType,
    referenceNo: string,
    specs: SpecsType[]
}
export interface OfferRes {
    availableBalance: number,
    quote: {
        details: DetailsType[]
        total: number
    }
}
export type DetailsType = {
    name: string,
    totalMarketAmount?: number,
    totalDiscountAmount: number
    charges: ChargesType[],
}
export type ChargesType = {
    billable: boolean
    desc: string
    originalDesc: string
    quantity: number
    marketPrice?: number
    discountPrice: number
    marketAmount?: number
    discountAmount: number
}
// 打单
export interface IntimidateData extends OfferData {
    createAndLabels: boolean,
}

export const createOrderModule = {
    // 获取FBA地址 
    getFbaAddress: () => sendGet<GetFbaAddressRes[]>('/address/fba'),
    // 邮编发生变化实时获取地址
    ChengeZip: (data: ChengeZipData) => sendPost<ChengeZipRes[]>('/address/find-city', data),
    // 报价
    offer: (data: OfferData) => sendPost<OfferRes>('/product/quote', data),
    // 打单
    intimidate: (data: IntimidateData) => sendPost('/order/create', data),
}