import { sendGet, sendPost } from './index'
import * as echarts from 'echarts/core'
import { LineSeriesOption } from "echarts/charts"
import { TitleComponentOption, TooltipComponentOption, GridComponentOption, LegendComponentOption } from "echarts/components";

// 登录
export interface LogonData {
    username: string,
    password: string
}
export interface LogonRes {
    accessToken: string,
    companyName: string,
    nickname: string,
    roles: string[]
    username: string,
}
// 修改密码
export interface ChangePasswordData {
    oldPassword: string,
    newPassword: string
}
//获取跳转DHL的token
export interface GetDhlTokenRes {
    data: string,
}
// 获取我的账户信息
export interface GetAccountInformationRes {
    availableBalance: string,
    creditAmount: string,
    freezeAmount: string,
    totalCost: string,
    totalIncome: string,
    totalRecharge: string
}
// 获取最近打单数据
export interface GetNewlyInfoData {
    startDate: string,
    endDate: string
}
export type GetNewlyInfoRes = echarts.ComposeOption<
    | TitleComponentOption
    | TooltipComponentOption
    | GridComponentOption
    | LegendComponentOption
    | LineSeriesOption
>

export const loginModule = {
    // 登录
    login: (data: LogonData) => sendPost<LogonRes>('/auth/sign-in', data),
    // 修改密码
    changePassword: (data: ChangePasswordData) => sendPost('/user/change-password', data),
    //获取跳转DHL的token
    getDhlToken: () => sendGet<GetDhlTokenRes>('/auth/partner-token'),
    // 获取我的账户信息
    getAccountInformation: () => sendGet<GetAccountInformationRes>('/account/info'),
    // 获取最近打单数据
    getNewlyInfo: (data: GetNewlyInfoData) => sendPost<GetNewlyInfoRes>('/stat/line-chart', data)
}