import React from 'react';
// import ReactDOM from 'react-dom/client';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux"
import store from "./redux/store"
import { message  } from 'antd';

import api from './api/index'
React.$api = api

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
const root = document.getElementById('root') as HTMLElement

// document.title = window.configInfo.name

message.config({
  maxCount: 1
})

// root.render(
//   // <React.StrictMode>
//   <Provider store={store}>
//     <App />
//   </Provider>
//   //  </React.StrictMode>
// );
// 使用render渲染是为了解决react-activation包使用ReactDOM.createRoot渲染会使antd样式异常
ReactDOM.render(<Provider store={store}><App /></Provider>, root);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();