// node 16.16.0
import { useEffect } from 'react'
import { RegisterRoute } from './router/index'
import emit from './utils/emit'
// BrowserRouter 路由历史模式    HashRouter 路由哈希模式
import { HashRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import 'antd/dist/antd.less';
import 'moment/locale/zh-cn'
import zhCN from 'antd/es/locale/zh_CN';
// import enUS from 'antd/es/locale/en_US';
import intl from 'react-intl-universal'
import { AliveScope } from 'react-activation'

const locales = {
  'en-US': require('./locale/en-US.json'),
  'zh-CN': require('./locale/zh-CN.json')
}

function App() {
  // const [antdLang, setAntdLang] = useState("" as any)

  // 初始化语言
  const loadLocales = () => {
    // let tem: any = localStorage.getItem("language")
    // if (!tem) {
    //   tem = ['en-US', 'zh-CN'].includes(navigator.language) ? navigator.language : 'en-US'
    // }

    // intl.init({
    //   currentLocale: tem,
    //   locales
    // }).then(() => {
    //   setAntdLang(tem === 'zh-CN' ? zhCN : enUS)
    // })
    intl.init({
      currentLocale: "zh-CN",
      locales
    })
  }

  useEffect(() => {
    // other code        
    loadLocales()
    emit.on('change_language', () => loadLocales())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ConfigProvider locale={zhCN}>
      <HashRouter>
        <AliveScope>
            <RegisterRoute />
        </AliveScope>
      </HashRouter>
    </ConfigProvider>
  )
}

export default App;
